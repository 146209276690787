<template>
  <router-link
    class="flex-btn auto-expand-button-height"
    v-bind="$props"
    v-on="$listeners"
    :event="$props.preventClick ? '' : 'click'"
  >
    <span>
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String
    },
    preventClick: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.auto-expand-button-height {
  height: auto;
}
</style>
