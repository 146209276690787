var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    _vm._g(
      _vm._b(
        {
          staticClass: "flex-btn auto-expand-button-height",
          attrs: { event: _vm.$props.preventClick ? "" : "click" },
        },
        "router-link",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [_c("span", [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }